import { Link } from '@remix-run/react';

import { Cookie, Flame, ListChecks, PackageCheck, Store } from 'lucide-react';

import { routes } from '~/utils/routes';

const features = [
  {
    name: 'Popular meal options',
    description:
      'Choose from a selection of our favourite and most common meals',
    icon: Flame,
    href: '/order-now/menu',
  },
  {
    name: 'Create your own',
    description:
      'Choose your protein, carbohydrates and salad or vegetables for each meal',
    icon: ListChecks,
    href: '/order-now/create-your-own',
  },
  {
    name: 'Snacks, Dips and juices',
    description:
      'Choose your favourite Snacks, Dips and Juices. These extras can be ordered with or without meals',
    icon: Cookie,
    href: routes.orderNow.snacksAndJuices.index,
  },
];

const deliveryMethods = [
  {
    name: 'Delivery',
    description: 'We can deliver your meals directly to your door',
    icon: PackageCheck,
  },
  {
    name: 'Pickup',
    description:
      'We also provide pickup as an option if you are outside of our delivery radius',
    icon: Store,
  },
];

export const Features = () => {
  return (
    <div className='overflow-hidden border-b bg-gray-50 py-16 sm:pt-28 lg:pb-24 lg:pt-36 dark:border-zinc-700 dark:bg-zinc-900'>
      <div className='relative mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8'>
        <svg
          className='absolute left-full hidden -translate-x-1/2 -translate-y-1/4 transform lg:block'
          width={404}
          height={784}
          fill='none'
          viewBox='0 0 404 784'
          aria-hidden='true'>
          <defs>
            <pattern
              id='b1e6e422-73f8-40a6-b5d9-c8586e37e0e7'
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits='userSpaceOnUse'>
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className='text-gray-200 md:text-gray-200 dark:text-zinc-800'
                fill='currentColor'
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill='url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)'
          />
        </svg>

        <div className='relative'>
          <h2 className='text-left text-3xl font-bold leading-8 tracking-tight text-zinc-900 sm:text-4xl dark:text-white'>
            How it works
          </h2>

          <p className='mx-auto mt-4 text-left text-xl text-zinc-500 dark:text-zinc-400'>
            Let&apos;s step you through the simple process to order your meals,
            snacks and juices.
          </p>
        </div>

        <div className='relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8'>
          <div className='relative'>
            <h3 className='text-2xl font-bold tracking-tight text-zinc-900 sm:text-3xl dark:text-white'>
              What we offer
            </h3>

            <p className='mt-3 text-lg text-zinc-500 dark:text-zinc-400'>
              {/* We offer flexibility when ordering meals. To make things easy for
              you, we provide a simple way to order your meals, snacks and
              juices */}
              We cater to your unique preferences with our flexible meal
              ordering system. Enjoy the simplicity of ordering meals, snacks,
              and invigorating juices through our online ordering platform
            </p>

            <dl className='mt-10 space-y-10'>
              {features.map((item) => (
                <div key={item.name} className='relative'>
                  <dt>
                    <div className='absolute flex h-12 w-12 items-center justify-center rounded-xl bg-brand-200 text-brand-900 dark:bg-brand-900 dark:text-brand-200'>
                      <item.icon className='h-5 w-5' aria-hidden='true' />
                    </div>

                    <p className='ml-16 text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-100'>
                      {item.name}
                    </p>
                  </dt>

                  <dd className='ml-16 mt-2 text-base text-zinc-500 dark:text-zinc-400'>
                    {item.description}
                  </dd>

                  <dd className='ml-16 mt-2 text-base text-zinc-500 dark:text-zinc-400'>
                    <Link
                      className='font-medium text-brand-600 hover:text-brand-500 dark:text-brand-500 dark:hover:text-brand-400'
                      to={item.href}>
                      Order now <span aria-hidden='true'>&rarr;</span>
                    </Link>
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div className='relative -mx-4 mt-10 lg:mt-0' aria-hidden='true'>
            <svg
              className='absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden'
              width={784}
              height={404}
              fill='none'
              viewBox='0 0 784 404'>
              <defs>
                <pattern
                  id='ca9667ae-9f92-4be7-abcb-9e3d727f2941'
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits='userSpaceOnUse'>
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className='text-gray-200 dark:text-zinc-800'
                    fill='currentColor'
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill='url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)'
              />
            </svg>

            <div className='relative mx-auto px-3'>
              <img
                className='relative rounded-2xl shadow-xl'
                width={490}
                src='/images/menu/menu-1-chicken-nuggets-tabbouleh-chicken-rice.jpg'
                alt='Chicken Nuggets, Tabbouli and Chicken Rice'
              />
            </div>
          </div>
        </div>

        <svg
          className='absolute right-full hidden translate-x-1/2 translate-y-12 transform lg:block'
          width={404}
          height={784}
          fill='none'
          viewBox='0 0 404 784'
          aria-hidden='true'>
          <defs>
            <pattern
              id='64e643ad-2176-4f86-b3d7-f2c5da3b6a6d'
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits='userSpaceOnUse'>
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className='text-gray-200 dark:text-zinc-800'
                fill='currentColor'
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill='url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)'
          />
        </svg>

        <div className='relative mt-12 sm:mt-16 lg:mt-24'>
          <div className='lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8'>
            <div className='lg:col-start-2'>
              <h3 className='text-2xl font-bold tracking-tight text-zinc-900 sm:text-3xl dark:text-white'>
                Delivery methods
              </h3>

              <p className='mt-3 text-lg text-zinc-500 dark:text-zinc-400'>
                We understand that everyone has a busy life. In light of this,
                we provide flexible delivery and pick-up times to suit your
                schedule.
              </p>

              <dl className='mt-10 space-y-10'>
                {deliveryMethods.map((item) => (
                  <div key={item.name} className='relative'>
                    <dt>
                      <div className='absolute flex h-12 w-12 items-center justify-center rounded-xl bg-brand-200 text-brand-900 dark:bg-brand-900 dark:text-brand-200'>
                        <item.icon className='h-5 w-5' aria-hidden='true' />
                      </div>

                      <p className='ml-16 text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-100'>
                        {item.name}
                      </p>
                    </dt>

                    <dd className='ml-16 mt-2 text-base text-zinc-500 dark:text-zinc-400'>
                      {item.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className='relative -mx-4 mt-10 lg:col-start-1 lg:mt-0'>
              <svg
                className='absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden'
                width={784}
                height={404}
                fill='none'
                viewBox='0 0 784 404'
                aria-hidden='true'>
                <defs>
                  <pattern
                    id='e80155a9-dfde-425a-b5ea-1f6fadd20131'
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits='userSpaceOnUse'>
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className='text-gray-200 dark:text-zinc-800'
                      fill='currentColor'
                    />
                  </pattern>
                </defs>
                <rect
                  width={784}
                  height={404}
                  fill='url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)'
                />
              </svg>

              <div className='relative mx-auto px-3'>
                <img
                  className='relative rounded-2xl shadow-xl'
                  width={490}
                  src='/images/menu/menu-4-caesar-salad-chicken-nuggets-chicken-rice.jpg'
                  alt='Caesar Salad, Chicken Nuggets and Chicken Rice'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
